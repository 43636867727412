






















import { Component, Vue } from 'vue-property-decorator'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

@Component({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  onHomepageHandler = () => {
    // @ts-ignore
    this.$refs.content.$refs.fullpage.api.moveTo('home')
  }
  onIconHandler = () => {
    // @ts-ignore
    this.$refs.content.$refs.fullpage.api.moveTo('home')
  }
  goToChuhe = () => {
    window.open('https://chuhe.xyz10.com/admin')
  }
}
