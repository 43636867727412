import { Component } from 'vue-property-decorator'
import * as tsx from "vue-tsx-support"
import Home from './views/Home.vue'
import MobileHome from './views/MobileHome.vue'

@Component({
  components: {
    Home,
    MobileHome
  }
})
export default class App extends tsx.Component<Record<string, unknown>> {
  showPc = true
  isPhone = () => {
    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  }
  mounted() {
    this.showPc = !this.isPhone()
  }
  protected render(): string {
    return (
      <div id="app">
        {
          this.showPc ? <Home /> : <MobileHome />
        }
      </div>
    )
  }
}
