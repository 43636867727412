






























































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class HelloWorld extends Vue {
  cooperate = [
    {
      name: '微软研究院',
      info: '共同维护行业最权威的时空数据集XYZ-NET',
      logo: require('../assets/截屏2021-12-09 23.08.12@2x.png'),
    },
    {
      name: '清华大学',
      info: '合作研发区块链技术',
      logo: require('../assets/63d0f703918fa0ec08faacc53fde4eee3d6d54fb6deb@2x.png'),
    },
    {
      name: '哈尔滨工业大学',
      info: '合作研发时空智能技术和应用场景',
      logo: require('../assets/0e2442a7d933c8952b5646f3d41373f08202000a@2x.png'),
    },
    {
      name: '浙江大学',
      info: '联合培养人工智能和时空智能的科研人才',
      logo: require('../assets/728da9773912b31bb0513050ae53217adab44aed4b3c@2x.png'),
    },
    {
      name: '南京大学',
      info: '合作研究图像识别算法',
      logo: require('../assets/2fdda3cc7cd98d103d73086f2c3fb80e7aec90cc@2x.png'),
    },
    {
      name: '明尼苏达大学',
      info: '合作研发定位算法、地图算法',
      logo: require('../assets/3801213fb80e7bec54e7bba5f666ae389b504fc23d14@2x.png'),
    },
  ]

  options = {
    autoScrolling: true,
    sectionsColor: ["#1b25d3", "#272b37", "#000", "#242d3e"],
    fitToSection: true,
    scrollOverflowReset: true,
    anchors: [
      'home',
      'productAdvantage',
      'applicationScene',
      'productFramework',
      'customerCase',
      'partner',
      'footer',
    ],
    scrollOverflow: true,
    verticalCentered: false,
    bigSectionsDestination: 'top',
  }

  showBox1ByHover = false
  showBox1ByClick = false
  showBox2ByHover = false
  showBox2ByClick = false
  showBox3ByHover = false
  showBox3ByClick = false
  showBox4ByHover = false
  showBox4ByClick = false

  showWeixinImgByHover = false
  showWeixinImgByClick = false
  showSupportImgByHover = false
  showSupportImgByClick = false

  goToChuhe = () => {
     window.open('https://chuhe.xyz10.com/admin')
  }
  skipToProductAdvantage = () => {
    //@ts-ignore
    this.$refs.fullpage.api.moveTo('productAdvantage')
  }
  skipToApplicationScene = () => {
    //@ts-ignore
    this.$refs.fullpage.api.moveTo('applicationScene')
  }
  skipToProductFramework = () => {
    //@ts-ignore
    this.$refs.fullpage.api.moveTo('productFramework')
  }
  skipToCustomerCase = () => {
    //@ts-ignore
    this.$refs.fullpage.api.moveTo('customerCase')
  }
  skipToPartner = () => {
    //@ts-ignore
    this.$refs.fullpage.api.moveTo('partner')
  }
}
