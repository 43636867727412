import Vue from 'vue'
import App from './App'
import router from './router'
import "vue-tsx-support/enable-check"
import './style.module.scss'
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js'
import 'fullpage.js/dist/fullpage.css'
import 'ant-design-vue/dist/antd.css'
import { Carousel } from 'ant-design-vue/lib'

Vue.use(VueFullPage)

Vue.component(Carousel.name, Carousel)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
